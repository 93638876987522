import {
  listBrls,
  listIndigo,
  listSingulares
} from './indrema'

const API = {
  indrema: {
    brls: {
      list: listBrls
    },
    indigo: {
      list: listIndigo
    },
    singulares: {
      list: listSingulares
    }
  }
}

export default API
