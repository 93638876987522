import { Auth } from 'aws-amplify'

export const getIdToken = async () => {
  const currentUser = await Auth.currentAuthenticatedUser()

  return currentUser.signInUserSession.idToken.jwtToken as string
}

export const getUserData = async () => {
  const currentUser = await Auth.currentAuthenticatedUser()
  const email = currentUser.signInUserSession.idToken.payload.email as string
  const username = currentUser.signInUserSession.idToken.payload['cognito:username'] as string

  return { email, username }
}
