import React from 'react'

import ManageSearchIcon from '@mui/icons-material/ManageSearch'

export const PAGES = {
  SEARCH: {
    id: 'search',
    url: '/busca',
    name: 'Busca',
    icon: <ManageSearchIcon />
  }
}

export const DATE_FORMAT = {
  DAILY: 'dd/MM/yyyy',
  MONTHLY: 'MM/yyyy'
}

export const CSV_ENCODING = 'ISO-8859-1'
export const CSV_DELIMITER = ';'
export const BUCKET_NAME = 'atrio-warehouse'
