import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { CognitoIdentityCredentials, fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import {
  S3Client as S3ClientAWS,
  S3ClientConfig,
  GetObjectCommand,
  PutObjectCommand,
  ListObjectsV2Command,
  HeadObjectCommand
} from '@aws-sdk/client-s3'
import { Buffer } from 'buffer'

import { AWS_COGNITO_REGION, AWS_IDENTITY_POOL_ID, AWS_IDENTITY_POOL_REGION, AWS_USER_POOLS_ID } from '../config/env'

export interface S3ClientProps {
  identityPoolId?: string
  region?: string
  s3Client?: S3ClientAWS
  accessToken: string
  credentials?: S3ClientConfig['credentials']
}

export type S3ClientType = {
  readFile: (input: { bucket: string, key: string }) => Promise<any>
  writeFile: (input: { bucket: string, key: string, data: any, contentType: string }) => Promise<any>
  listFiles: (input: { bucket: string, prefix: string, continuationToken?: string, delimiter?: string }) => Promise<{
    key: string | undefined;
    lastModified: Date | undefined;
    size: number | undefined;
  }[]>
  getPresignedUrl: (input: {
    bucket: string;
    key: string;
    expires: number;
    filename?: string;
  }) => Promise<string>
  headFile: ({ bucket, key }: {
    bucket: string;
    key: string;
  }) => Promise<{
    lastModified?: Date
    versionId?: string
    contentEncoding?: string
    contentType?: string
    metadata?: Record<string, string>
  }>
  _client: S3ClientAWS
}

const S3Client = ({
  identityPoolId,
  region,
  s3Client,
  credentials,
  accessToken
}: S3ClientProps): S3ClientType => {
  const internalRegion = region || AWS_IDENTITY_POOL_REGION
  const internalIdentityPoolId = identityPoolId || AWS_IDENTITY_POOL_ID

  const internalCredentials =
    credentials || fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: internalRegion }),
      identityPoolId: internalIdentityPoolId,
      logins: {
        [`cognito-idp.${AWS_COGNITO_REGION}.amazonaws.com/${AWS_USER_POOLS_ID}`]: accessToken
      }
    })

  const client = s3Client || new S3ClientAWS({
    region: 'us-east-1',
    credentials: internalCredentials
  })

  const readFile = async ({ bucket, key }: { bucket: string, key: string }) => {
    const command = new GetObjectCommand({
      Bucket: bucket,
      Key: key
    })

    const data = await client.send(command)

    if (!isReadableStream(data.Body)) {
      throw new Error(
        'Expected stream to be instance of ReadableStream, but got ' +
        typeof data.Body
      )
    }

    const contentType = data.ContentType || ''

    if (['application/octet-stream', 'binary/octet-stream'].includes(contentType)) {
      return streamToBuffer(data.Body)
    }

    const bodyContent = await streamToString(data.Body)

    try {
      if (data.ContentType === 'application/json') {
        return JSON.parse(bodyContent)
      }

      return bodyContent
    } catch (err) {
      return bodyContent
    }
  }

  const writeFile = async ({ bucket, key, data, contentType }: { bucket: string, key: string, data: any, contentType: string }) => {
    const body =
      contentType === 'application/json' ? JSON.stringify(data) : data

    const command = new PutObjectCommand({
      Bucket: bucket,
      Key: key,
      Body: body,
      ContentType: contentType
    })

    return await client.send(command)
  }

  const listFiles = async ({ bucket, prefix, continuationToken, delimiter }: { bucket: string, prefix: string, continuationToken?: string, delimiter?: string }) => {
    let internalContinuationToken: string | undefined = continuationToken
    const list = []

    do {
      const command = new ListObjectsV2Command({
        Bucket: bucket,
        Prefix: prefix,
        Delimiter: delimiter,
        ContinuationToken: internalContinuationToken
      })

      const response = await client.send(command)

      internalContinuationToken = response.NextContinuationToken
      const mappedContents = (response.Contents || []).map((content) => {
        return {
          key: content.Key,
          lastModified: content.LastModified,
          size: content.Size
        }
      })
      list.push(...mappedContents)
    } while (internalContinuationToken)

    return list
  }

  const getPresignedUrl = async ({ bucket, key, expires, filename }: { bucket: string, key: string, expires: number, filename?: string }) => {
    const command = new GetObjectCommand({
      Bucket: bucket,
      Key: key,
      ResponseContentDisposition: filename ? `attachment; filename="${filename}"` : undefined
    })

    const url = await getSignedUrl(client, command, {
      expiresIn: expires
    })

    return url
  }

  const headFile = async ({ bucket, key }: { bucket: string, key: string }) => {
    const command = new HeadObjectCommand({
      Bucket: bucket,
      Key: key
    })

    const output = await client.send(command)
    const { LastModified, VersionId, ContentEncoding, ContentType, Metadata } = output

    return {
      lastModified: LastModified,
      versionId: VersionId,
      contentEncoding: ContentEncoding,
      contentType: ContentType,
      metadata: Metadata
    }
  }

  return {
    _client: client,
    readFile,
    writeFile,
    listFiles,
    getPresignedUrl,
    headFile
  }
}

async function streamToBuffer (stream: ReadableStream<any>): Promise<Buffer> {
  return new Promise((resolve, reject) => {
    const array: number[] = []

    const reader = stream.getReader()
    const processRead = ({ done, value }: ReadableStreamDefaultReadResult<number[]>) => {
      if (done) {
        resolve(Buffer.from(array))
        return
      }

      value.forEach(v => array.push(v))

      // Not done, keep reading
      reader.read().then(processRead).catch(reject)
    }

    // start read
    reader.read().then(processRead).catch(reject)
  })
}

const isReadableStream = (stream?: any): stream is ReadableStream<any> => {
  return stream instanceof ReadableStream
}

async function streamToString (stream: ReadableStream<any>): Promise<string> {
  return new Promise((resolve, reject) => {
    let text = ''
    const decoder = new TextDecoder('utf-8')

    const reader = stream.getReader()
    const processRead = ({ done, value }: ReadableStreamDefaultReadResult<BufferSource>) => {
      if (done) {
        resolve(text)
        return
      }

      text += decoder.decode(value)

      // Not done, keep reading
      reader.read().then(processRead).catch(reject)
    }

    // start read
    reader.read().then(processRead).catch(reject)
  })
}

export class StaticS3Client {
  private static instance?: S3ClientType
  private static accessToken?: string
  private static credentials?: CognitoIdentityCredentials

  private static async resolveCredentials () {
    if (!StaticS3Client.accessToken) {
      throw new Error('Access token is required')
    }

    const credentials = await fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: AWS_IDENTITY_POOL_REGION }),
      identityPoolId: AWS_IDENTITY_POOL_ID,
      logins: {
        [`cognito-idp.${AWS_COGNITO_REGION}.amazonaws.com/${AWS_USER_POOLS_ID}`]: StaticS3Client.accessToken
      }
    })()

    StaticS3Client.credentials = credentials
  }

  private static resolveInstance () {
    if (!StaticS3Client.accessToken) {
      throw new Error('Access token is required')
    }
    StaticS3Client.instance = S3Client({ accessToken: StaticS3Client.accessToken, credentials: StaticS3Client.credentials })
  }

  static async getInstance ({ accessToken }: { accessToken: string }): Promise<S3ClientType> {
    const isNewToken = !StaticS3Client.accessToken || StaticS3Client.accessToken !== accessToken
    if (isNewToken) {
      StaticS3Client.accessToken = accessToken
      StaticS3Client.credentials = undefined
      StaticS3Client.instance = undefined

      await StaticS3Client.resolveCredentials()
    }

    const isCredentialsExpired = StaticS3Client.credentials?.expiration && StaticS3Client.credentials.expiration.getTime() <= Date.now()
    if (isCredentialsExpired) {
      await StaticS3Client.resolveCredentials()
    }

    const isNewInstanceRequired = isNewToken || isCredentialsExpired

    if (!StaticS3Client.instance || isNewInstanceRequired) {
      StaticS3Client.resolveInstance()
    }

    return StaticS3Client.instance!
  }
}

export default S3Client
