export function notEmpty<TValue> (value: TValue): value is NonNullable<TValue> {
  return value !== null && value !== undefined
}

export function notEmptyString (value: string | undefined | null): value is NonNullable<string> {
  if (value === undefined || value === null) {
    return false
  }

  return value.length > 0
}

export function movingAverage (values: number[], period: number): number[] {
  const result: number[] = []

  for (let i = 0; i < values.length; i++) {
    const sum = values.slice(i, i + period).reduce((a, b) => a + b, 0)
    result.push(sum / period)
  }

  return result
}

export const mean = (array: number[]) => {
  if (array.length === 0) {
    return 0
  }

  const length = array.length

  return array.reduce((total, item) => total + item, 0) / length
}

export const standardDeviation = (array: number[]) => {
  if (array.length === 0) {
    return 0
  }

  const length = array.length

  const meanValue = mean(array)
  return Math.sqrt(
    array.reduce((total, item) => total + Math.pow(item - meanValue, 2), 0) /
    length
  )
}
