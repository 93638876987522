import { getDay, isAfter, isBefore, isEqual, isValid } from 'date-fns'
import { ptBR, enUS } from 'date-fns/locale'
import { zonedTimeToUtc, format } from 'date-fns-tz'

const compareUTCDates = (dateA: Date, dateB: Date): number => {
  const values: { [key: string]: number[] } = {
    years: [dateA.getUTCFullYear(), dateB.getUTCFullYear()],
    months: [dateA.getUTCMonth(), dateB.getUTCMonth()],
    days: [dateA.getUTCDate(), dateB.getUTCDate()],
    hours: [dateA.getUTCHours(), dateB.getUTCHours()],
    minutes: [dateA.getUTCMinutes(), dateB.getUTCMinutes()],
    seconds: [dateA.getUTCSeconds(), dateB.getUTCSeconds()],
    milliseconds: [dateA.getUTCMilliseconds(), dateB.getUTCMilliseconds()]
  }

  for (const unit in values) {
    const unitValues = values[unit]

    const diff = unitValues[0] - unitValues[1]
    if (diff !== 0) {
      return diff
    }
  }

  return 0
}

export const isUTCDateAfter = (dateA: Date, dateB: Date): boolean => {
  return compareUTCDates(dateA, dateB) > 0
}

export const isUTCDateAfterOrSame = (dateA: Date, dateB: Date): boolean => {
  return compareUTCDates(dateA, dateB) >= 0
}

export const isUTCDateBefore = (dateA: Date, dateB: Date): boolean => {
  return compareUTCDates(dateA, dateB) < 0
}

export const isUTCDateBeforeOrSame = (dateA: Date, dateB: Date): boolean => {
  return compareUTCDates(dateA, dateB) <= 0
}

export const isBusinessDay = (date: Date): boolean => {
  const dayOfWeek = getDay(date)

  return dayOfWeek !== 0 && dayOfWeek !== 6
}

export const isAfterOrEqual = (dateA: Date, dateB: Date): boolean => {
  return isEqual(dateA, dateB) || isAfter(dateA, dateB)
}

export const isBeforeOrEqual = (dateA: Date, dateB: Date): boolean => {
  return isEqual(dateA, dateB) || isBefore(dateA, dateB)
}

export const isValidDate = (date: Date): boolean => {
  return isValid(date)
}

export const factoryDate = (date: string | number | Date, timezone = 'America/Sao_Paulo'): Date => {
  if (!timezone) {
    return new Date(date)
  }

  return zonedTimeToUtc(date, timezone)
}

type formatWithTimezoneProps = (
  date: string | number | Date,
  pattern: string,
  props?: {
    timezone?: string,
    locale?: 'pt-BR' | 'en-US'
  }) => string

export const formatWithTimezone: formatWithTimezoneProps = (date, pattern, props): string => {
  const internalLocale = props?.locale === 'pt-BR' ? ptBR : enUS
  const internalTimezone = props?.timezone || 'America/Sao_Paulo'
  return format(date, pattern, { timeZone: internalTimezone, locale: internalLocale })
}

export const validateAndFormatInEnUs = (date?: Date): string => {
  if (!date) {
    return '-'
  }

  return isValidDate(date) ? formatWithTimezone(date, 'MM/dd/yyyy', { locale: 'en-US' }) : '-'
}
