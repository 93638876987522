import React from 'react'
import { Box, Paper, Typography } from '@mui/material'

import UnauthorizedAccessIcon from '../../assets/icons/UnauthorizedAccessIcon'

export interface UnauthorizedAccessProps {

}

export default function UnauthorizedAccess (props: UnauthorizedAccessProps) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: 'secondary.main',
      height: '100vh',
      overflow: 'hidden'
    }}>
      <Paper
        sx={{
          px: 2,
          py: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          overflowY: 'auto',
          maxHeight: '90vh',
          width: '75%',
          maxWidth: '600px'
        }}
      >
        <UnauthorizedAccessIcon style={{ height: 200 }} />
        <Typography component='h1' variant='h4' sx={{ my: 2 }}>Acesso não autorizado!</Typography>
        <Typography>Você não tem permissão a permissão necessária para acessar esta página.</Typography>
      </Paper>
    </Box>
  )
}
