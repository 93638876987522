import { PROGRESS_STATUS, PROGRESS_TYPES } from '../../utils/progress-status'

// Action Types
export const Types = {
  SET_BRL: 'FILTERS/SET_BRL',
  SET_INDIGO: 'FILTERS/SET_INDIGO',
  SET_SINGULARE: 'FILTERS/SET_SINGULARE'
}

type Keys = keyof typeof Types
type Values = typeof Types[Keys]

export type Providers = 'brl' | 'singulare' | 'indigo'
// Reducer
export interface IInitialState {
  options: {
    brl?: {
      funds: IFund[],
      status: PROGRESS_STATUS
    },
    indigo?: {
      funds: IFund[],
      status: PROGRESS_STATUS
    }
    singulare?: {
      funds: IFund[],
      status: PROGRESS_STATUS
    }
  },
  values: {
    provider: Providers | undefined,
    fund: string | undefined,
    data: 'wallets' | 'stocks' | 'tlbs' | undefined,
    date: string | undefined
  }
}

export interface IFund {
  name: string,
  wallets: IWallet[]
  stocks: IStock[]
  tlbs: ITlb[]
}

export interface IWallet {
  date: Date,
  s3Path: string,
}

export interface IStock {
  date: Date,
  s3Path: string,
}

export interface ITlb {
  date: Date,
  s3Path: string,
}

const initialState: IInitialState = {
  options: {
    brl: {
      funds: [],
      status: PROGRESS_TYPES.PENDING
    },
    indigo: {
      funds: [],
      status: PROGRESS_TYPES.PENDING
    },
    singulare: {
      funds: [],
      status: PROGRESS_TYPES.PENDING
    }
  },
  values: {
    provider: undefined,
    fund: undefined,
    data: undefined,
    date: undefined
  }
}

interface IAction {
  type: Values,
  payload: Partial<IInitialState>
}

export default function reducer (state = initialState, action: IAction): IInitialState {
  switch (action.type) {
    case Types.SET_BRL: {
      const { status, funds } = action.payload.options?.brl ?? {}
      return {
        ...state,
        options: {
          ...state.options,
          brl: {
            status: status ?? PROGRESS_TYPES.PENDING,
            funds: funds ?? []
          }
        }
      }
    }
    case Types.SET_INDIGO: {
      const { status, funds } = action.payload.options?.indigo ?? {}
      return {
        ...state,
        options: {
          ...state.options,
          indigo: {
            status: status ?? PROGRESS_TYPES.PENDING,
            funds: funds ?? []
          }
        }
      }
    }
    case Types.SET_SINGULARE: {
      const { status, funds } = action.payload.options?.singulare ?? {}
      return {
        ...state,
        options: {
          ...state.options,
          singulare: {
            status: status ?? PROGRESS_TYPES.PENDING,
            funds: funds ?? []
          }
        }
      }
    }
    default:
      return state
  }
}

// Action Creators
export function setBrlOptions (data: IInitialState['options']['brl']): IAction {
  return {
    type: Types.SET_BRL,
    payload: { options: { brl: data } }
  }
}

export function setSingulareOptions (data: IInitialState['options']['singulare']): IAction {
  return {
    type: Types.SET_SINGULARE,
    payload: { options: { singulare: data } }
  }
}

export function setIndigoOptions (data: IInitialState['options']['indigo']): IAction {
  return {
    type: Types.SET_INDIGO,
    payload: { options: { indigo: data } }
  }
}
