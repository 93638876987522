import React, { useEffect, useState } from 'react'
import { Box, Container, IconButton, Toolbar, Typography } from '@mui/material'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'

import { setBrlOptions, setIndigoOptions, setSingulareOptions } from './redux/stores/filters'

import lazyLoad from './lib/lazyLoad'

import API from './services/api'

import { PAGES } from './config/consts'
import { HEAD_TITLE, LOGO } from './config/env'

import { useAppDispatch, useHandleError } from './utils/hooks'
import { PROGRESS_TYPES } from './utils/progress-status'

import { AppBar, DrawerMenu, ErrorBoundary, ErrorLoadPage, Footer, LazyLoadPage } from './components'

import {
  SearchProps
} from './pages'

const Search = lazyLoad<SearchProps>(() => import('./pages/search'), ErrorLoadPage, 3, 1000)

const drawerWidth = 240

function App (): JSX.Element {
  const { handleError } = useHandleError()
  const dispatch = useAppDispatch()
  const location = useLocation()

  const [openSide, setOpenSide] = useState(false)

  useEffect(() => {
    const getBrls = async () => API.indrema.brls.list().then(funds => {
      dispatch(setBrlOptions({ funds, status: PROGRESS_TYPES.LOADED }))
    }).catch(handleError)

    const getSingulares = async () => API.indrema.singulares.list().then(funds => {
      dispatch(setSingulareOptions({ funds, status: PROGRESS_TYPES.LOADED }))
    }).catch(handleError)

    const getIndigo = async () => API.indrema.indigo.list().then(funds => {
      dispatch(setIndigoOptions({ funds, status: PROGRESS_TYPES.LOADED }))
    }).catch(handleError)

    Promise.allSettled([
      getBrls(),
      getIndigo(),
      getSingulares()
    ])
  }, [dispatch, handleError])

  const handleToggleDrawer = (): void => {
    setOpenSide(!openSide)
  }

  const resolvePage = (): string => {
    const dict: { [key: string]: string } = Object.values(PAGES).reduce((obj: { [url: string]: string }, { url, name }) => {
      obj[url] = name
      return obj
    }, {})

    const path = location.pathname
    return dict[path] || 'Dashboard'
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <AppBar position='absolute' open={openSide} drawerWidth={drawerWidth}>
          <Toolbar
            sx={{
              pr: '24px' // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={handleToggleDrawer}
              sx={{
                marginRight: '36px',
                ...(openSide && { display: 'none' })
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {resolvePage()}
            </Typography>
            <Box>
              <img src={LOGO} alt={HEAD_TITLE} height={35} />
            </Box>
          </Toolbar>
        </AppBar>
        <DrawerMenu open={openSide} onToggle={handleToggleDrawer} drawerWidth={drawerWidth} />
        <Box
          component='main'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            flexDirection: 'column',
            display: 'flex',
            overflow: 'hidden'
          }}
        >
          <Toolbar />
          <Box sx={{ pt: 2, pb: 2, flex: 1, overflow: 'auto' }}>
            <Container maxWidth={false} sx={{ flex: 1 }}>
              <LazyLoadPage>
                <Routes>
                  <Route path={PAGES.SEARCH.url} element={<ErrorBoundary><Search /></ErrorBoundary>} />
                  <Route path='*' element={<Navigate to={PAGES.SEARCH.url} state={{ from: location }} replace />} />
                </Routes>
              </LazyLoadPage>
            </Container>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box >
  )
}

export default App
