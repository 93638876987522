import { DataFormValue } from '../../pages/search/components'

// Action Types
export const Types = {
  SET_STATUS_DATA: 'MAIN_DATA/SET_STATUS',
  SET_DATA: 'MAIN_DATA/SET_DATA',
  SET_LIFETIME: 'MAIN_DATA/SET_LIFETIME',
  SET_FILTER_VALUES: 'MAIN_DATA/SET_FILTER_VALUES'
}

type Keys = keyof typeof Types
type Values = typeof Types[Keys]

// Reducer
export interface IInitialState {
  filterValues: Partial<DataFormValue>
}

const initialState: IInitialState = {
  filterValues: {
    provider: null,
    fund: null,
    date: null,
    data: null
  }
}

interface IAction {
  type: Values,
  payload: Partial<IInitialState>
}

export default function reducer (state = initialState, action: IAction) {
  switch (action.type) {
    case Types.SET_FILTER_VALUES: {
      const filterValues = action.payload.filterValues ?? {}
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          ...filterValues
        }
      }
    }
    default:
      return state
  }
}

// Action Creators
export function setFilterValues (data: Partial<IInitialState>): IAction {
  return {
    type: Types.SET_FILTER_VALUES,
    payload: data
  }
}
