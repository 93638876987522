import { getIdToken } from '../aws'

import { StaticS3Client } from '../../lib/s3'

import { BUCKET_NAME } from '../../config/consts'
import { STAGE } from '../../config/env'

import { factoryDate } from '../../utils/date'
import { notEmptyString } from '../../utils/array'
import { IFund } from '../../redux/stores/filters'

const uniqueArray = (array: Array<string | null | undefined>) => {
  return Array.from(new Set(array.filter(notEmptyString).map((item) => item.toString())))
}

export const listBrls = async (): Promise<Array<IFund>> => {
  const accessToken = await getIdToken()
  const s3Client = await StaticS3Client.getInstance({ accessToken })

  const items = await s3Client.listFiles({
    bucket: BUCKET_NAME,
    delimiter: 'raw',
    prefix: `stage=${STAGE}/resource=etl/system=brl/`
  })

  const mappedItems = items.map(({ key }) => {
    if (!key) return null
    const regex = /stage=(.+)\/resource=etl\/system=(.+)\/fundo=(.+)\/report=(.+)\/date=(.+)\/(.+)/
    const match = key.match(regex)
    const fund = match?.[3]
    const report = match?.[4]
    const date = match?.[5]

    if (!fund || !report || !date) return null

    return {
      fund,
      date,
      report,
      key
    }
  }).filter(Boolean) as Array<{
    fund: string,
    date: string,
    report: string,
    key: string
  }>

  const funds = uniqueArray(mappedItems.map(item => item.fund)).map(fund => {
    const wallets = mappedItems
      .filter(item => item.fund === fund && item.report === 'carteira')
      .map(item => {
        return {
          date: factoryDate(item.date),
          s3Path: item.key
        }
      })

    const stocks = mappedItems
      .filter(item => item.fund === fund && item.report === 'estoque')
      .map(item => {
        return {
          date: factoryDate(item.date),
          s3Path: item.key
        }
      })

    const tlbs = mappedItems
      .filter(item => item.fund === fund && item.report === 'tlb')
      .map(item => {
        return {
          date: factoryDate(item.date),
          s3Path: item.key
        }
      })

    return {
      name: fund,
      wallets,
      stocks,
      tlbs
    }
  })

  return funds
}

export const listIndigo = async (): Promise<Array<IFund>> => {
  const accessToken = await getIdToken()
  const s3Client = await StaticS3Client.getInstance({ accessToken })

  const items = await s3Client.listFiles({
    bucket: BUCKET_NAME,
    prefix: `stage=${STAGE}/resource=etl/system=indigo/`
  })

  const mappedItems = items.map(({ key }) => {
    if (!key) return null
    const regex = /stage=(.+)\/resource=etl\/system=(.+)\/fundo=(.+)\/report=(.+)\/date=(.+)\/(.+)/
    const match = key.match(regex)
    const fund = match?.[3]
    const report = match?.[4]
    const date = match?.[5]

    if (!fund || !report || !date) return null

    return {
      id: `${fund}-${report}-${date}`,
      fund,
      date,
      report,
      key
    }
  }).filter(Boolean) as Array<{
    id: string,
    fund: string,
    date: string,
    report: string,
    key: string
  }>

  const uniqueItems = Array.from(new Map(mappedItems.map(({ id, ...item }) => [id, item])).values())

  const funds = uniqueArray(uniqueItems.map(item => item.fund)).map(fund => {
    const wallets = mappedItems
      .filter(item => item.fund === fund && item.report === 'carteira')
      .map(item => {
        return {
          date: factoryDate(item.date),
          s3Path: item.key
        }
      })

    const stocks = uniqueItems
      .filter(item => item.fund === fund && item.report === 'estoque')
      .map(item => {
        return {
          date: factoryDate(item.date),
          s3Path: item.key
        }
      })

    const tlbs = uniqueItems
      .filter(item => item.fund === fund && item.report === 'tlb')
      .map(item => {
        return {
          date: factoryDate(item.date),
          s3Path: item.key
        }
      })

    return {
      name: fund,
      wallets,
      stocks,
      tlbs
    }
  })

  return funds
}

export const listSingulares = async (): Promise<Array<IFund>> => {
  const accessToken = await getIdToken()
  const s3Client = await StaticS3Client.getInstance({ accessToken })

  const items = await s3Client.listFiles({
    bucket: BUCKET_NAME,
    prefix: `stage=${STAGE}/resource=etl/system=singulare/`
  })

  const mappedItems = items.map(({ key }) => {
    if (!key) return null
    const regex = /stage=(.+)\/resource=etl\/system=(.+)\/fundo=(.+)\/report=(.+)\/date=(.+)\/(.+)/
    const match = key.match(regex)
    const fund = match?.[3]
    const report = match?.[4]
    const date = match?.[5]

    if (!fund || !report || !date) return null

    return {
      id: `${fund}-${report}-${date}`,
      fund,
      date,
      report,
      key
    }
  }).filter(Boolean) as Array<{
    id: string,
    fund: string,
    date: string,
    report: string,
    key: string
  }>

  const uniqueItems = Array.from(new Map(mappedItems.map(({ id, ...item }) => [id, item])).values())

  const funds = uniqueArray(uniqueItems.map(item => item.fund)).map(fund => {
    const wallets = mappedItems
      .filter(item => item.fund === fund && item.report === 'carteira')
      .map(item => {
        return {
          date: factoryDate(item.date),
          s3Path: item.key
        }
      })

    const stocks = uniqueItems
      .filter(item => item.fund === fund && item.report === 'estoque')
      .map(item => {
        return {
          date: factoryDate(item.date),
          s3Path: item.key
        }
      })

    const tlbs = uniqueItems
      .filter(item => item.fund === fund && item.report === 'tlb')
      .map(item => {
        return {
          date: factoryDate(item.date),
          s3Path: item.key
        }
      })

    return {
      name: fund,
      wallets,
      stocks,
      tlbs
    }
  })

  return funds
}
